import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFrown } from "@fortawesome/free-solid-svg-icons"
import Layout from "../components/layout/Layout"

const NotFound = () => (
  <Layout>
    <section id="contact" className="h-screen">
      <div className="container mx-auto p-8 pt-20 text-center">
        <FontAwesomeIcon icon={faFrown} size="6x" className="text-gray-800"/>
        <h1 className="md:text-4xl text-3xl font-semibold tracking-tight justify-center mt-2">404: Not Found</h1>
        <p className="md:text-lg font-light text-center mt-4 space-y-4">
          You just hit a page that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </section>
  </Layout>
)
export default NotFound